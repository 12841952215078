// Step 1: Import React
import Layout from '../../components/layout/LayoutComponentEn'
import React, { useLayoutEffect } from 'react'
import { ScrollMoves } from "../../components/gsapFunctions"

// Step 2: Define your component
const IndexPage = (props) => {

  useLayoutEffect(() => {
    let moves = ScrollMoves();
    return () => {
      moves.forEach((move) => {
        move.scrollTrigger.kill();
      })
    }
  })

  return (
    <Layout pageTitle="Inicio" content='' isHome={true}>
      <>
        <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
            
          <div className='flex flex-col 2xl:mx-64 sm:mx-72 mx-0 text-center my-20 gap-16 bg-white/0'>
            <div>
                <h3 className='font-bold text-2xl'>NEUROMED & CARDIOMED RICARDO AND COMPANY LIMITED (NECARMED CIA LTDA).</h3>
                <p className='text-xl text-justify mt-8'>We are a company oriented to the health sector since our foundation in 2010. Throughout these years we have been dedicated to the sale, marketing, distribution, import and export of supplies, medical equipment and implants of high specialty, with a clear vision of the social responsibility that involves this economic activity.</p>
                <p className='text-xl text-justify mt-8'>With our extensive experience and solid alliances with our suppliers we offer our customers high quality products and technology, which meet their needs, distributing them with high levels of planning throughout the national territory. In addition, we have a modern organization and a team of capable and dedicated professionals, persevering in the continuous growth of our company.</p>
                <p className='text-xl text-justify mt-8'>We are a company committed to satisfying the needs of our customers, providing the best quality in medical supplies and equipment for the health sector within the highest ethical principles and social responsibility.</p>
                
            </div>
            <div className='flex flex-col'>
              <h3 className='font-bold text-2xl text-center mb-10'>OUR COMPANY IS BASED ON 2 STRATEGIC PRINCIPLES</h3>
              <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center'>
                <div className='move-right basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 md:p-10 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-8 text-center '>Personalized attention</h3>
                  <p>We are a leading company in providing personalized and quality attention to customers.</p>
                </div>
                <div className='move-left basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 md:p-10 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-8 text-center '>Integral Services</h3>
                  <p>We are a company that provides all the integral services required by our clients, so that we guarantee their entire satisfaction.</p>
                </div>
              </div>
            </div>



            <div className='flex flex-col md:gap-20 gap-10 justify-center'>
                <div className='move-up basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-4 text-center '>Mission</h3>
                  <p>Our mission is to provide an efficient service, offering internationally recognized brands, ensuring the quality of all our commercial activities to maintain guaranteed satisfaction and fruitful relationships with our customers and suppliers.</p>
                </div>
                <div className='move-up basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-4 text-center '>Vision</h3>
                  <p>Our vision is to be recognized as leaders in the national market, for our ability to meet the requirements of our customers, with quality and innovative products that meet their needs.</p>
                </div>
                <div className='move-up basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-4 text-center '>Our Team</h3>
                  <p>We have a team of highly trained Surgical Technicians, with the ability to provide surgical assistance in the operating rooms, who also have the knowledge and ability to promote the products and services we market.</p>
                  <p>Our Team of Engineers is trained to offer after-sales services, which increases customer satisfaction and guarantees the quality of our services.</p>
                </div>
              </div>
          </div>
        </div>
        
      </>
    </Layout>
  )
}
// Step 3: Export your component
export default IndexPage

export const Head = () => (
  <>
    <title>Sobre Nosotros | NecarMed</title>
  </>
)